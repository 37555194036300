import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import PropTypes from 'prop-types'
import ContentsList from './ContentsList'
import Single from '../components/Single'
import GLOBAL from '../Global'
import '../css/Home.css'
import '../css/ScheduleTour.css'
import '../css/Contents.css'

// const END_POINT ='https://mk-official-data.firebaseio.com/contents.json'
const END_POINT = '/data/creation.json'

class Contents extends Component {
  constructor(props) {
    super(props)
    this.state = {
      topics: [],
    }
  }

  componentDidMount() {
    axios.get(END_POINT).then(result => {
      const host = window.location.hostname
      let data
      if (host === 'localhost') {
        data = result.data.filter(e => e !== null)
      } else {
        data = result.data.filter(e => e !== null && e.show !== 'hidden')
      }
      this.setState({ topics: data })
    })
  }

  render() {
    const {
      props: { history },
      state: { topics },
    } = this
    const path = history.location.pathname // 現在のパス
    if (path === '/') {
      // HOME出力用
      return (
        <main className="home">
          <div className="mainInner">
            <h1 className="mainVisual">{GLOBAL.HOME.MAINVISUAL}</h1>
            <div className="mainContainer">
              <div className="contents">
                <ContentsList data={topics} />
              </div>
            </div>
          </div>
        </main>
      )
    }
    return <Single category="contents" data={topics} path={path} />
  }
}

export default withRouter(Contents)

Contents.propTypes = {
  history: PropTypes.object,
}

Contents.defaultProps = {
  history: null,
}
