/* eslint-disable react/no-danger */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import GLOBAL from '../Global'
import PropTypes from 'prop-types'

const END_POINT = '/data/link.json'

class WorldMap extends Component {
  constructor(props) {
    super(props)
    this.path = props.history.location.pathname // 現在のパス
    this.state = {
      link: [],
    }
  }

  componentDidMount() {
    axios.get(END_POINT).then(result => {
      this.setState({ link: result.data })
    })
  }

  render() {
    const { link } = this.state
    return (
      <>
        <div className="mainHeading onlyEnglish">
          <h1>{GLOBAL.LINK.TITLE}</h1>
        </div>
        <div className="contents">
          <ul className="list archive">
            {link.map((e, i) => {
              return (
                <a
                  className="listPart"
                  href={e.link}
                  key={i}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <li>
                    <div className="thumbnail">
                      <img src={e.image} alt={e.text} />
                    </div>
                    <div className="titleBox">{e.text}</div>
                  </li>
                </a>
              )
            })}
          </ul>
        </div>
      </>
    )
  }
}
export default withRouter(WorldMap)
WorldMap.propTypes = {
  history: PropTypes.object.isRequired,
}
