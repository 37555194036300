import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import _ from 'lodash'
import Pagination from '@material-ui/lab/Pagination'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

let keepState = ''
let keepPage = ''
let keepData = []

class ContentsList extends Component {
  constructor() {
    super()
    this.state = {
      stateData: [],
      current: keepState !== '' ? keepState : 'all',
      page: keepPage !== '' ? keepPage : 1,
    }
  }

  componentDidMount() {
    if (keepData.length === 0) {
      this.setState({ current: 'all' })
      keepState = ''
      this.setState({
        page: 1,
      })
      keepPage = ''
    }
    if (keepState !== '') {
      this.category(keepState, keepData)
    }
    if (keepPage !== '') {
      this.setState({
        page: keepPage,
      })
    }
  }

  stateInitialize = () => {
    this.setState({
      current: 'all',
      page: 1,
    })
  }

  category(e, data) {
    this.stateInitialize()
    if (e === 'all') {
      this.setState({ stateData: data })
    } else if (e === 'info') {
      const category = data.filter(el => el.category.includes(e))
      this.setState({ stateData: category, current: e })
    } else {
      const category = data.filter(el => el.category.includes(e))
      this.setState({ stateData: category, current: e })
    }
  }

  handlePage(e, value) {
    this.setState({ page: value })
    window.scrollTo(0, 0)
    keepPage = value
  }

  render() {
    const { stateData, current, page } = this.state

    keepState = current

    const { data } = this.props
    let selectedData
    if (stateData.length === 0) {
      selectedData = data
    } else {
      selectedData = stateData
    }
    const allData = _.orderBy(selectedData, ['generalId'], ['desc'])
    let reverseData = allData.filter(e => e !== undefined || e !== null)
    reverseData = reverseData.filter(e => e && e.dependency !== 'child')

    const dataLength = reverseData.length
    const perPage = 20
    let pageNumber = 1
    if (dataLength > perPage) {
      pageNumber = parseInt(dataLength / perPage, 10) + 1
      reverseData = reverseData.slice(perPage * (page - 1), perPage * page)
    }
    if (data.length === 0) {
      return (
        <div className="nowLoading">
          <CircularProgress size="6rem" />
        </div>
      )
    }
    keepData = data

    const category = ['all', '2Dimensions', '3Dimensions','MANKIND']

    return (
      <>
        <div className="tourCategory">
          {category.map(e => {
            return (
              <Button
                key={e}
                disabled={
                  e !== 'all' &&
                  data.filter(el => el.category.includes(e)).length === 0
                }
                className={current === e ? 'current' : ''}
                variant="contained"
                onClick={() => {
                  this.category(e, data)
                }}
              >
                {e.toUpperCase()}
              </Button>
            )
          })}
        </div>
        <div className="mainContainer">
          <div className="mainBody">
            <ul className="list archive">
              {reverseData.map((e, i) => {
                const link = `/contents/${e.slug}`
                // const tag = e.tag
                let thumbNail
                if (e.thumbnailUrl === '') {
                  thumbNail = `/images/creation/${e.imageUrl[0]}`
                } else {
                  thumbNail = `/images/creation/${e.thumbnailUrl}`
                }
                return (
                  <Link to={link} key={e.slug} className="listPart">
                    {/* <span className={`tag ${e.brandCategory[0]}`}>{tag}</span> */}
                    <li>
                      <div className="thumbnail">
                        <img src={thumbNail} alt={e.title} />
                      </div>
                      <div className="titleBox">
                        {e.title}
                        {/* <span className="date">{e.date}</span> */}
                      </div>
                    </li>
                  </Link>
                )
              })}
            </ul>
            <Pagination
              count={pageNumber}
              className="pagination"
              page={page}
              onChange={(e, value) => {
                this.handlePage(e, value)
              }}
            />
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(ContentsList)

ContentsList.propTypes = {
  data: PropTypes.array,
}

ContentsList.defaultProps = {
  data: null,
}
