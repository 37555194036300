import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Main from './Main'

class Content extends Component {
  constructor(props) {
    super()
    this.page = props.page
    this.path = props.location.pathname // ここで現在のパスを取得
  }

  render() {
    const main = <Main page={this.page} />
    return <div className="content">{main}</div>
  }
}

export default withRouter(Content)

Content.propTypes = {
  page: PropTypes.string,
  location: PropTypes.object,
}

Content.defaultProps = {
  page: null,
  location: null,
}
