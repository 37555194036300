/* eslint-disable react/no-danger */
import React from 'react'
import { withRouter } from 'react-router-dom'
import GLOBAL from '../Global'
import mkc from '../images/midknitecreation.png'

const About = () => {
  return (
    <>
      <div className="mainHeading onlyEnglish">
        <h1>{GLOBAL.ABOUT.TITLE}</h1>
      </div>
      <div className="mainContent">
        <div className="mainVisual">
          <img src={mkc} alt="" />
        </div>
        <div
          className="textBox"
          dangerouslySetInnerHTML={{ __html: GLOBAL.ABOUT.TEXT }}
        />
      </div>
    </>
  )
}

export default withRouter(About)
