export default {
  SITENAME: 'MID KNITE CREATION',
  HOME: {
    MAINVISUAL: 'Draw A Dream.',
  },
  ABOUT: {
    TITLE: 'ABOUT',
    TEXT:
      '<p><b>MID KNITE CREATION</b></p><p>A TEAM OF MID KNITE JAPAN INTERNATIONAL LTD.</p>',
  },
  LINK: {
    TITLE: 'LINK',
  },
  COPYRIGHT: '&copy;MID KNITE JAPAN INTERNATIONAL LTD. ALL RIGHTS RESERVED.',
}
