import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import Header from './components/Header'
import Content from './components/Content'
import Footer from './components/Footer'
import ScrollToTop from './components/ScrollToTop'
import Logo from './images/logo.png'
import ToTop from './images/toTop.png'
// import GA from './utils/GoogleAnalytics'

window.addEventListener('scroll', () => {
  const toTop = document.getElementById('toTopButton')
  if (window.pageYOffset >= 500) {
    toTop.style.display = 'block'
  } else {
    toTop.style.display = 'none'
  }
})

const App = () => {
  return (
    <div className="App">
      <div className="loading">
        <img src={Logo} alt="LOGO" width="300" />
      </div>
      <div className="AppInner">
        <CssBaseline />
        <Router>
          {/* {GA.init() && <GA.RouteTracker />} */}
          <ScrollToTop>
            <Header />
            <Route path="/about" render={() => <Content page="ABOUT" />} />
            <Route path="/link" render={() => <Content page="LINK" />} />
            {/* <Route
              path="/worldshop"
              render={() => <Content page="WORLD SHOP" />}
            /> */}
            <Route
              path="/contents"
              render={() => <Content page="CONTENTS" />}
            />
            <Route exact path="/" render={() => <Content page="CONTENTS" />} />
            <Footer />
            <div
              id="toTopButton"
              className="toTopButton"
              onClick={() => {
                window.scrollTo(0, 0)
              }}
            >
              <img src={ToTop} alt="" width="50" />
            </div>
          </ScrollToTop>
        </Router>
      </div>
    </div>
  )
}

export default App
