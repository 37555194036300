import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import axios from 'axios'
import Logo from '../images/logo.png'
import '../css/Header.css'

const END_POINT = '/data/navi.json'

class Header extends Component {
  constructor() {
    super()
    this.state = {
      navi: [],
      toggle: false,
    }
  }

  componentDidMount() {
    axios.get(END_POINT).then(result => {
      this.setState({ navi: result.data })
    })
  }

  executeMenu = () => {
    const { toggle } = this.state
    this.setState({ toggle: !toggle })
    if (toggle) {
      document.body.style.overflow = 'visible'
    } else {
      document.body.style.overflow = 'hidden'
    }
  }

  closeMenu =() => {
    this.setState({ toggle: false })
    document.body.style.overflow = 'visible'
  }

  render() {
    const {
      state: { toggle, navi },
    } = this
    return (
      <header className="header" id="header">
        <div className="menu">
          <div
            className={`menu-trigger${toggle ? " active" : ''}`} 
            onClick={this.executeMenu}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className="headerInner">
          <div className="logo">
            <a href="/">
              <img src={Logo} alt="LOGO" width="255" />
            </a>
          </div>
          <ul className={`menuList--pc menuList${toggle ? ' active' : ''}`}>
            <li>
              <Link to="/" onClick={this.closeMenu}>
                <img src={Logo} alt="LOGO" width="200" />
              </Link>
            </li>
            {navi.map(e => {
              return (
                <li key={e.text}>
                  <NavLink
                    to={e.link}
                    onClick={this.closeMenu}
                    activeClassName="current"
                  >
                    {e.text}
                  </NavLink>
                </li>
              )
            })}
          </ul>
          <ul className={`menuList--sp menuList${toggle ? ' active' : ''}`}>
            <li>
              <Link to="/" onClick={this.closeMenu}>
                <img src={Logo} alt="LOGO" width="200" />
              </Link>
            </li>
            {navi.map(e => {
              return (
                <li key={e.text}>
                  <NavLink
                    to={e.link}
                    onClick={this.closeMenu}
                    activeClassName="current"
                  >
                    {e.text}
                  </NavLink>
                </li>
              )
            })}
          </ul>
        </div>
      </header>
    )
  }
}

export default Header
